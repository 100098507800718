import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from './Title.module.css';

export const Title = () => {
  // const { t } = useTranslation();

  return (
    <div className={clsx(styles['list-headline'])}>
      <div className={clsx(styles['list-headline__ttl'])}>
        <svg xmlns="http://www.w3.org/2000/svg" width="330" height="50" viewBox="0 0 330 50">
          <g>
            <g>
              <path
                d="M44.007,23.523a12,12,0,0,1,.609,4.553,8.727,8.727,0,0,1-1.208,3.863,10.231,10.231,0,0,1-2.942,3.086,17.292,17.292,0,0,1-4.758,2.248l-2.412.766,2.929,9.225a.658.658,0,0,1-.018.448,1.074,1.074,0,0,1-.388.444,4.9,4.9,0,0,1-.837.462,12.96,12.96,0,0,1-1.408.521,12.814,12.814,0,0,1-1.44.383,4.974,4.974,0,0,1-.961.109.992.992,0,0,1-.562-.141.68.68,0,0,1-.261-.36l-8.256-26a2.187,2.187,0,0,1,.048-1.748,2.088,2.088,0,0,1,1.274-.982l6.812-2.163q1.029-.327,1.98-.543a21.439,21.439,0,0,1,2.329-.37,10.249,10.249,0,0,1,2.927.116,8.189,8.189,0,0,1,2.824,1.033A7.687,7.687,0,0,1,42.505,20.5a10.153,10.153,0,0,1,1.5,3.027M38,25.9a5.445,5.445,0,0,0-1.33-2.332,3.888,3.888,0,0,0-1.779-1.033,4.568,4.568,0,0,0-1.829-.071,13.241,13.241,0,0,0-1.816.441l-2.5.8L31.855,33.5l2.637-.836a6.685,6.685,0,0,0,2.235-1.128,4.226,4.226,0,0,0,1.216-1.555,4.822,4.822,0,0,0,.4-1.921A6.868,6.868,0,0,0,38,25.9"
                fill="#ff9298"
              />
              <path
                d="M74.755,35.64a9.888,9.888,0,0,1,.191,1.17,3.471,3.471,0,0,1,0,.815,1.017,1.017,0,0,1-.19.509.642.642,0,0,1-.371.224L60.794,41.284a1.91,1.91,0,0,1-1.367-.173,1.87,1.87,0,0,1-.829-1.344L52.8,12.845a.649.649,0,0,1,.059-.444,1.076,1.076,0,0,1,.429-.4,5.026,5.026,0,0,1,.889-.382,15.862,15.862,0,0,1,2.921-.629,5.048,5.048,0,0,1,.956-.015,1.073,1.073,0,0,1,.556.192.654.654,0,0,1,.239.38l5.136,23.852,9.326-2.008a.68.68,0,0,1,.427.039.939.939,0,0,1,.375.352,3.091,3.091,0,0,1,.335.718,10.021,10.021,0,0,1,.307,1.145"
                fill="#ff9298"
              />
              <path
                d="M107.337,31.086a8.14,8.14,0,0,1,.532,1.275.807.807,0,0,1-.084.757,1.524,1.524,0,0,1-.864.427q-.64.149-1.779.307-1.184.165-1.85.222a4.579,4.579,0,0,1-1.03,0,.939.939,0,0,1-.527-.222,1.617,1.617,0,0,1-.3-.455l-2.859-5.755L87.294,29.211l-1.087,6.138a1.927,1.927,0,0,1-.167.58,1.006,1.006,0,0,1-.44.415,3.6,3.6,0,0,1-.928.307q-.609.132-1.606.271a15.187,15.187,0,0,1-1.682.162,1.285,1.285,0,0,1-.882-.219.918.918,0,0,1-.267-.733,7.65,7.65,0,0,1,.167-1.348L85.959,6.846a2.344,2.344,0,0,1,.236-.684,1.171,1.171,0,0,1,.54-.454,4.77,4.77,0,0,1,1.1-.318q.716-.135,1.9-.3,1.37-.191,2.187-.268a5.9,5.9,0,0,1,1.289-.014,1.226,1.226,0,0,1,.7.293,2.3,2.3,0,0,1,.433.649ZM90.484,10.973l-.022,0L87.988,24.334l8.52-1.184Z"
                fill="#ff9298"
              />
              <path
                d="M140.913,28.741a2.362,2.362,0,0,1-.122.972,2.038,2.038,0,0,1-.456.735,1.942,1.942,0,0,1-.721.459,3.079,3.079,0,0,1-.868.188l-2.619.175A5.4,5.4,0,0,1,134.7,31.2a2.809,2.809,0,0,1-1.14-.523,5.2,5.2,0,0,1-1.048-1.1,21.774,21.774,0,0,1-1.18-1.836L122.854,14.1q-.739-1.218-1.515-2.634T119.932,8.7l-.047,0q.206,1.677.365,3.347t.278,3.445l1.062,15.832a.729.729,0,0,1-.1.43.955.955,0,0,1-.423.345,3.533,3.533,0,0,1-.84.256,11.112,11.112,0,0,1-1.375.162,10.921,10.921,0,0,1-1.361.022,3.412,3.412,0,0,1-.855-.143.884.884,0,0,1-.442-.287.782.782,0,0,1-.145-.413L114.224,4.5a2.052,2.052,0,0,1,.533-1.692,2.347,2.347,0,0,1,1.541-.655l3.3-.222a6.329,6.329,0,0,1,1.506.052,3.106,3.106,0,0,1,1.122.43,3.9,3.9,0,0,1,.964.91,12.541,12.541,0,0,1,.969,1.474l6.635,10.666q.582.971,1.149,1.908t1.1,1.876q.532.939,1.04,1.844t.99,1.813l.024,0q-.18-1.632-.334-3.407t-.263-3.388l-.951-14.2a.683.683,0,0,1,.111-.43,1.125,1.125,0,0,1,.446-.359,3.1,3.1,0,0,1,.852-.257,13.108,13.108,0,0,1,1.376-.15A12.589,12.589,0,0,1,137.67.688a2.642,2.642,0,0,1,.844.144.944.944,0,0,1,.43.3.783.783,0,0,1,.146.413Z"
                fill="#ff9298"
              />
              <path
                d="M202.708,30.6a.729.729,0,0,1-.143.418.909.909,0,0,1-.479.288,4.652,4.652,0,0,1-.908.156,14.2,14.2,0,0,1-2.834-.1,4.346,4.346,0,0,1-.884-.218.976.976,0,0,1-.458-.321.677.677,0,0,1-.126-.426l.849-24.665-.047,0-9.632,24.339a1.129,1.129,0,0,1-.322.493,1.492,1.492,0,0,1-.584.3,3.938,3.938,0,0,1-.931.12q-.564.016-1.36-.012a13.333,13.333,0,0,1-1.357-.1,4.058,4.058,0,0,1-.919-.219,1.7,1.7,0,0,1-.562-.336.9.9,0,0,1-.266-.455L174.114,4.926h-.047l-.849,24.665a.724.724,0,0,1-.144.417.91.91,0,0,1-.49.288,4.778,4.778,0,0,1-.908.157,11.645,11.645,0,0,1-1.42.021,11.509,11.509,0,0,1-1.4-.119,4.6,4.6,0,0,1-.9-.218.9.9,0,0,1-.458-.321.72.72,0,0,1-.114-.426l.929-27.008a2.472,2.472,0,0,1,.7-1.8A2.278,2.278,0,0,1,170.719,0l4.029.139a8.53,8.53,0,0,1,1.844.239,3.6,3.6,0,0,1,1.315.631,3.342,3.342,0,0,1,.9,1.111,7.758,7.758,0,0,1,.6,1.673l5.937,18.286.094,0L192.85,4.3a9.473,9.473,0,0,1,.724-1.639,3.865,3.865,0,0,1,.882-1.073,2.783,2.783,0,0,1,1.156-.558,5.918,5.918,0,0,1,1.529-.123l4.146.142a3.24,3.24,0,0,1,1.083.2,1.862,1.862,0,0,1,.734.506,2.1,2.1,0,0,1,.417.788,3.337,3.337,0,0,1,.118,1.048Z"
                fill="#ff9298"
              />
              <path
                d="M231.616,31.989a9.019,9.019,0,0,1-.178,1.124,2.892,2.892,0,0,1-.252.716.934.934,0,0,1-.335.378.641.641,0,0,1-.4.087l-15.15-1.6a1.922,1.922,0,0,1-1.247-.592,1.874,1.874,0,0,1-.368-1.535l2.776-26.291a1.872,1.872,0,0,1,.68-1.425,1.919,1.919,0,0,1,1.342-.318l15.057,1.59a.629.629,0,0,1,.361.155.907.907,0,0,1,.236.438,3.143,3.143,0,0,1,.1.764,9.414,9.414,0,0,1-.063,1.16,9.13,9.13,0,0,1-.175,1.1,2.964,2.964,0,0,1-.252.716,1.022,1.022,0,0,1-.323.379.6.6,0,0,1-.387.088L222.269,7.789l-.778,7.365,9.114.962a.641.641,0,0,1,.371.169.936.936,0,0,1,.249.427,2.729,2.729,0,0,1,.1.741,8.913,8.913,0,0,1-.059,1.113,8.8,8.8,0,0,1-.176,1.112,2.785,2.785,0,0,1-.249.693.865.865,0,0,1-.332.353.688.688,0,0,1-.4.077L221,19.839l-.9,8.507,10.862,1.147a.648.648,0,0,1,.371.168.962.962,0,0,1,.248.439,3.026,3.026,0,0,1,.1.752,9.484,9.484,0,0,1-.062,1.137"
                fill="#ff9298"
              />
              <path
                d="M265.946,37.908a2.344,2.344,0,0,1-.354.912,2.023,2.023,0,0,1-.621.6,1.945,1.945,0,0,1-.81.271,3.074,3.074,0,0,1-.889-.029l-2.583-.464a5.37,5.37,0,0,1-1.367-.413,2.831,2.831,0,0,1-.978-.783,5.188,5.188,0,0,1-.75-1.326q-.33-.81-.7-2.066l-4.919-15.292q-.422-1.36-.831-2.923t-.695-3.018l-.047-.009q-.207,1.677-.456,3.335t-.566,3.411l-2.808,15.616a.729.729,0,0,1-.2.393.966.966,0,0,1-.495.232A3.487,3.487,0,0,1,245,36.4a11.022,11.022,0,0,1-1.373-.175,10.775,10.775,0,0,1-1.326-.31,3.378,3.378,0,0,1-.795-.345.883.883,0,0,1-.36-.387.78.78,0,0,1-.04-.435l4.823-26.828a2.052,2.052,0,0,1,.927-1.512,2.353,2.353,0,0,1,1.655-.262l3.252.584a6.34,6.34,0,0,1,1.45.416,3.1,3.1,0,0,1,.983.689,3.932,3.932,0,0,1,.717,1.117,12.682,12.682,0,0,1,.581,1.664l3.851,11.956q.328,1.084.654,2.13t.612,2.087q.29,1.04.562,2.042t.522,2l.023,0q.221-1.627.5-3.387t.567-3.351l2.517-14a.689.689,0,0,1,.214-.389,1.116,1.116,0,0,1,.52-.24,3.079,3.079,0,0,1,.889-.043,13.394,13.394,0,0,1,1.371.187,13.035,13.035,0,0,1,1.3.294,2.647,2.647,0,0,1,.783.344.949.949,0,0,1,.346.4.775.775,0,0,1,.041.436Z"
                fill="#ff9298"
              />
              <path
                d="M302.819,38.613a14.205,14.205,0,0,1-2.217,4.548,9.792,9.792,0,0,1-3.38,2.879,10.484,10.484,0,0,1-4.437,1.094,16.73,16.73,0,0,1-5.393-.781,17.1,17.1,0,0,1-4.713-2.156,9.947,9.947,0,0,1-3.061-3.178,9.442,9.442,0,0,1-1.264-4.158,14.139,14.139,0,0,1,.656-5.1L284.3,14.09a.688.688,0,0,1,.254-.365,1.009,1.009,0,0,1,.571-.147,5.178,5.178,0,0,1,.964.093,12.8,12.8,0,0,1,1.457.362,13.006,13.006,0,0,1,1.394.49,4.981,4.981,0,0,1,.844.448,1.072,1.072,0,0,1,.395.437.648.648,0,0,1,.026.448l-5.131,17.155a9.823,9.823,0,0,0-.47,3.125,5.755,5.755,0,0,0,.6,2.466,5.27,5.27,0,0,0,1.535,1.817,7.356,7.356,0,0,0,2.358,1.158,7.139,7.139,0,0,0,2.62.318,5.161,5.161,0,0,0,2.236-.688,5.861,5.861,0,0,0,1.783-1.668,8.682,8.682,0,0,0,1.232-2.605l5.232-17.492a.685.685,0,0,1,.255-.363.991.991,0,0,1,.559-.152,5.019,5.019,0,0,1,.963.093,13.317,13.317,0,0,1,1.447.359,12.778,12.778,0,0,1,1.382.487,4.822,4.822,0,0,1,.833.445.983.983,0,0,1,.385.433.683.683,0,0,1,.014.444Z"
                fill="#ff9298"
              />
              <path
                d="M330,44.158a4.007,4.007,0,1,0-4.006,4.066A4.036,4.036,0,0,0,330,44.158"
                fill="#ff9298"
              />
              <path
                d="M8.013,44.158a4.036,4.036,0,0,0-4.006-4.066,4.066,4.066,0,0,0,0,8.132,4.036,4.036,0,0,0,4.006-4.066"
                fill="#ff9298"
              />
            </g>
          </g>
        </svg>
      </div>
      <div className={clsx(styles['list-headline__ic'])}>
        <i className={clsx(styles['primary'])}></i>
        <i className={clsx(styles['secondary'])}></i>
        <i className={clsx(styles['secondary'], styles['top'])}></i>
        <i className={clsx(styles['primary'])}></i>
        <i className={clsx(styles['secondary'])}></i>
        <i className={clsx(styles['primary'])}></i>
        <i className={clsx(styles['secondary'])}></i>
        <i className={clsx(styles['primary'])}></i>
        <i className={clsx(styles['secondary'])}></i>
        <i className={clsx(styles['primary'], styles['top'])}></i>
        <i className={clsx(styles['primary'])}></i>
        <i className={clsx(styles['secondary'])}></i>
      </div>
      {/* <h2>{t('Products')}</h2> */}
    </div>
  );
};
