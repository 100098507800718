import * as React from 'react';
import clsx from 'clsx';

import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { TopPageCustomSections } from 'components/TopPageCustomSections/TopPageCustomSections';
import { ProductListViewProps } from 'lib/themes/themes';
import baseStyles from 'components/Themes/Family/base.module.css';

import { KeyVisualView } from './KeyVisualView';
// import styles from './ProductListView.module.css';

import { Title } from './Title/Title';

const ProductListView = ({
  bannerImageUrls,
  bannerTitle,
  bannerDescription,
  productSummaries,
}: ProductListViewProps) => {
  return (
    <div className={clsx(baseStyles['base-theme'])}>
      <KeyVisualView
        imageUrls={bannerImageUrls}
        bannerTitle={bannerTitle}
        bannerDescription={bannerDescription}
      />

      <TopPageCustomSections />
      <Title />

      <ul className={clsx(baseStyles['base-inner'])}>
        {productSummaries.map((productSummary, idx) => (
          <ProductSummaryCard key={idx} product={productSummary} />
        ))}
      </ul>
    </div>
  );
};

export default ProductListView;
